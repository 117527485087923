<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 18:13:09
 * @LastEditTime: 2021-03-09 15:15:45
 * @FilePath: \acuconference-web\src\views\HostMeeting.vue
-->
<template>
  <v-card style="height: 100%; display: flex; flex-direction: column">
    <v-card-title>
      {{ $t("label_conference_rooms") }}
    </v-card-title>
    <v-card-subtitle>
      <div style="display: flex">
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          style="color: white; margin-right: 10px"
          right
          @click="refreshList"
          :disabled="!!refreshDisbaled"
        >
          {{ $t("label_btn_refresh") }}
        </v-btn>
        <v-btn outlined color="primary" right @click="addRoom">
          {{ $t("label_room_add_room") }}
        </v-btn>
      </div>
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text style="flex-grow: 1">
      <v-data-table
        id="hostMeetingTable"
        :disable-sort="true"
        :locale="tableLocal"
        style="width: 100%; height: 100%; display: flex; flex-direction: column"
        :items-per-page="limit"
        :loading="loading"
        hide-default-header
        :items="list"
        :page="page"
        :server-items-length="totalCount"
        @update:page="pageUpdate"
        @update:items-per-page="limitUpdate"
        :footer-props="tableFooterOptions"
      >
        <template v-slot:no-data>
          <span>
            {{ $t("table_no_data") }}
          </span>
        </template>
        <template v-slot:item="{ item }">
          <v-container>
            <v-row>
              <v-col class="room-Info-wrapper" cols="12" md="8" sm="6">
                <div class="room-info-name">
                  {{ item.name }}
                  <v-icon right v-if="item.security === 1 || item.highSecurity"> mdi-lock </v-icon>
                  <v-icon right v-else-if="item.security === 2" :title="item.roomInfo.accessCode">
                    mdi-keyboard
                  </v-icon>
                </div>
                <div class="room-info-invite-code">
                  <v-subheader style="padding: 0; height: unset">
                    {{ item.inviteCode }}
                  </v-subheader>
                </div>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <div
                  class="room-info-actions"
                  :style="{
                    'justify-content': $vuetify.breakpoint.xs
                      ? 'flex-start'
                      : 'flex-end',
                  }"
                >
                  <v-btn
                    color="primary"
                    fab
                    small
                    :title="$t('title_btn_Start_conference')"
                    @click="startMeeting(item)"
                    v-if="item.status === 0"
                  >
                    <v-icon color="white"> mdi-play </v-icon>
                  </v-btn>
                  <v-btn
                    color="green"
                    fab
                    small
                    :title="$t('title_btn_Join_Conference')"
                    @click="startMeeting(item)"
                    v-else-if="item.status === 1"
                  >
                    <v-icon color="white"> mdi-login </v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    fab
                    small
                    :title="$t('title_btn_Copy_invite_link')"
                    @click="copyMeeting(item)"
                  >
                    <v-icon color="white"> mdi-content-copy </v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    fab
                    small
                    :title="$t('title_btn_Edit')"
                    :disabled="item.status === 1"
                    @click="editMeeting(item)"
                  >
                    <v-icon color="white"> mdi-pencil </v-icon>
                  </v-btn>
                  <v-btn
                    color="red"
                    fab
                    small
                    :title="$t('title_btn_Delete')"
                    @click="confirmDeleteConference(item)"
                  >
                    <v-icon color="white"> mdi-delete </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-data-table>
    </v-card-text>
    <EditConference
      v-model="showEditConferenceDialog"
      :conference="activeConference"
      :enterprise="enterprise"
      @finishEditConference="initUserRooms"
    />
    <StartMeeting
      ref="checkStartMeeting"
      :room="activeConference"
      v-model="startMeetingCheck"
    />
    <ChangePasswordDialog
      v-model="showChangePasswordDialog"
      :userId="currentUserInfo.userInfo.id"
      :enterpriseId="getCurrentUserEnterprise"
      :forceEditPassword="forceEditPassword"
    />
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import apis from "../mixins/apis.js";
import EditConference from "../components/dialogs/editConference";
import StartMeeting from "../components/startMeeting";
import ChangePasswordDialog from "../components/dialogs/changePassword";
export default {
  mixins: [apis],
  components: {
    EditConference,
    StartMeeting,
    ChangePasswordDialog,
  },
  data() {
    return {
      limit: 10,
      page: 1,
      totalCount: 0,
      list: [],
      showEditConferenceDialog: false,
      startMeetingCheck: false,
      showChangePasswordDialog: false,
      forceEditPassword: false,
      enterprise: {},
      activeConference: {},
      refreshDisbaled: 0,
      refreshTimer: 0,
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.initUserRooms();
      },
    },
    limit() {
      this.initUserRooms();
    },
    showEditConferenceDialog(val) {
      if (!val) {
        this.enterprise = {};
        this.activeConference = {};
      }
    },
    startMeetingCheck(val) {
      if (!val) {
        this.enterprise = {};
        this.activeConference = {};
      }
    },
    showChangePasswordDialog(val) {
      if (!val) {
        if (this.forceEditPassword) {
          this.forceEditPassword = false;
          this.refreshUserData();
        }
      }
    },
    "currentUserInfo.userInfo.id"() {
      this.checkUserInfo()
    }
  },
  computed: {
    getPage() {
      return Math.max(this.page + 1, 0);
    },
    ...mapState("userInfo", ["currentUserInfo"]),
    headers() {
      return [
        {
          value: "infoWrapper",
        },
        {
          value: "actions",
        },
      ];
    },
    getCurrentUserEnterprise() {
      return this.currentUserInfo.userInfo.enterpriseId;
    },
  },
  methods: {
    ...mapActions("userInfo", ["refreshCurrentUserData"]),
    async confirmDeleteConference(item) {
      const confirm = await this.$confirm.warn(
        this.$t("label_delete_confirm"),
        {
          title: this.$t("label_warn"),
          buttons: [
            {
              label: this.$t("label_btn_cancel"),
              value: "cancel",
              color: "primary",
            },
            {
              label: this.$t("label_btn_confirm"),
              value: "confirm",
              color: "primary",
            },
          ],
        }
      );
      if (confirm === "confirm") {
        const ids = [item.id];
        const result = await this.deleteConference({
          ids,
        });
        if (result) {
          this.$toast.success(this.$t("prompt_success"));
          this.$nextTick(() => this.refreshList());
        }
      }
    },
    refreshList() {
      this.refreshDisbaled = 2;
      this.refreshTimer = setInterval(() => {
        this.refreshDisbaled -= 1;
        if (this.refreshDisbaled === 0) {
          clearInterval(this.refreshTimer);
        }
      }, 1000);
      this.initUserRooms();
    },
    async initUserRooms() {
      if (this.currentUserInfo.userInfo.id) {
        const params = {
          l: this.limit,
          o: this.limit * (this.page - 1),
        };
        const result = await this.getUserRoomsApi(
          this.currentUserInfo.userInfo.id,
          params
        );
        if (result && typeof result.total === "number") {
          const { rooms, total } = result;
          this.list = rooms;
          this.totalCount = total;
        }
      } else {
        this.$login()
      }
    },
    checkUserInfo() {
      if (!this.currentUserInfo.userInfo.id) {
        this.$router.push({
          name: "Home",
        });
        return;
      } else if (this.currentUserInfo.userInfo.type < 300) {
        this.$router.push({
          name: "Home",
        });
        return;
      }
      this.initUserRooms();
    },
    async addRoom() {
      this.activeConference = {};
      const result = await this.getEnterpriseById(
        this.currentUserInfo.userInfo.enterpriseId
      );
      if (result) {
        this.enterprise = result;
        this.showEditConferenceDialog = true;
      }
    },
    async editMeeting(item) {
      const result = await this.getEnterpriseById(item.enterpriseId);
      if (result) {
        this.enterprise = result;
      }
      if (this.enterprise) {
        let roomInfo = await this.getConferenceInfoApi(item.id);
        if (roomInfo) {
          this.activeConference = roomInfo;
          this.showEditConferenceDialog = true;
        }
      }
    },
    copyMeeting(item) {
      const link = location.origin + "/#/joinmeeting/" + item.inviteCode;
      if (this.$utils.copyText(link)) {
        this.$toast.success(this.$t("prompt_success"));
      }
    },
    pageUpdate(page) {
      this.page = page;
    },
    limitUpdate(limit) {
      this.limit = limit;
    },
    startMeeting(item) {
      this.activeConference = item;
      this.$nextTick(() => this.$refs.checkStartMeeting.checkRoomType());
    },
    async refreshUserData() {
      try {
        const result = await this.refreshCurrentUserData();
        if (result && result.needChangePassword) {
          this.forceModifiyPassword();
        }
      } catch (error) {
        process.env.NODE_ENV === "development" && console.log(error);
      }
      return;
    },
    forceModifiyPassword() {
      this.forceEditPassword = true;
      this.showChangePasswordDialog = true;
    },
  },
};
</script>

<style lang="sass" scoped>
.room-Info-wrapper
  position: relative
  width: 100%
  display: flex
  flex-direction: column
  .room-info-name
    font-size: 20px
.room-info-actions
  position: relative
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: flex-end
  &.mobile-actions
    flex-direction: column
    align-items: unset
    button
      margin-right: unset
      margin-bottom: 16px
  button
    margin-right: 16px
</style>