<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-26 11:47:04
 * @LastEditTime: 2021-03-10 12:07:08
 * @FilePath: \acuconference-web\src\components\startMeeting.vue
-->
<template>
  <div class="dialogs-wrapper">
    <v-dialog
      v-model="showInputPasswordDialog"
      max-width="300"
      :persistent="loading"
      @keydown.enter="getJoinLinkAndOpenIt"
    >
      <v-card :loading="loading" :disabled="loading">
        <v-card-title>
          {{ $t("label_Access_code") }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('label_Access_code')"
            v-model="accessCode"
            maxLength="500"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getJoinLinkAndOpenIt">
            {{ $t("label_tab_JoinMeeting") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Verfiy2fa
      :forModify="false"
      @refresh="finishVerfiy2fa"
      v-model="showVerfiy2fa"
    />
    <v-dialog v-model="showPromptSafariDialog" max-width="450">
      <v-card>
        <v-card-title style="word-break: unset">
          {{ $t("label_open_window_failed") }}
        </v-card-title>
        <v-card-text>
          <img style="width: 100%" :src="safariGuideImg" alt="" />
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn text color="primary" @click="showPromptSafariDialog = false">
            {{ $t("label_btn_close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import apis from "../mixins/startMeetingApis";
import Verfiy2fa from "./dialogs/verfiy2fa";
import safariGuideImg from "../assets/safariGuide.jpeg";
export default {
  mixins: [apis],
  components: { Verfiy2fa },
  props: {
    room: {
      type: Object,
      default() {
        return {};
      },
    },
    inviteCode: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showInputPasswordDialog: false,
      useRoom: {},
      accessCode: "",
      showVerfiy2fa: false,
      waitFor2FA: false,
      showPromptSafariDialog: false,
      activeLink: undefined,
      safariGuideImg,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.checkRoomType();
      } else {
        this.waitFor2FA = false;
        this.showVerfiy2fa = false;
        this.accessCode = "";
        this.useRoom = {};
      }
    },
    loading(val) {
      this.$emit("toggleLoadingStatus", val);
    },
  },
  computed: {
    ...mapState("userInfo", ["currentUserInfo"]),
  },
  methods: {
    async checkRoomType() {
      if (this.room.id) {
        this.useRoom = JSON.parse(JSON.stringify(this.room));
      } else if (this.inviteCode) {
        const result = await this.getRoomInfoByInviteCode(this.inviteCode);
        if (result.id) {
          this.useRoom = result;
        }
      }
      if (this.useRoom.highSecurity) {
        // 启用双因素验证
        // 先验证用户有没有登录
        if (!this.currentUserInfo.userInfo.id) {
          // 提示需要登陆
          this.$toast.error(
            this.$t("label_error_domain_conference_need_login")
          );
          return;
        }

        if (this.currentUserInfo.userInfo.twofaSecretCodeIsReset) {
          // 未设置双因素验证，提示设置双因素验证
          this.$toast.error(this.$t("label_error_join_need_verify_2fa"));
          return;
        }

        // 双因素验证
        this.waitFor2FA = true;
        this.showVerfiy2fa = true;
      } else {
        // 未启用双因素验证，直接走常规流程
        this.verification();
      }
    },
    finishVerfiy2fa() {
      if (this.waitFor2FA) {
        this.verification();
      }
    },
    verification() {
      this.showPromptSafariDialog = false;
      this.activeLink = undefined;
      if (
        this.useRoom.userId &&
        this.useRoom.userId === this.currentUserInfo.userInfo.id
      ) {
        // 是自己的会议就直接调接口
        this.accessCode = this.useRoom.roomInfo.accessCode;
        this.getJoinLinkAndOpenIt();
        return;
      }

      let roomEnterpriseId, userEnterpriseId;
      switch (this.useRoom.security) {
        case 0:
          // none
          this.getJoinLinkAndOpenIt();
          break;
        case 1:
          // same domain
          roomEnterpriseId = this.useRoom.enterpriseId;
          userEnterpriseId = this.currentUserInfo.userInfo.enterpriseId;
          if (!userEnterpriseId) {
            this.$toast.error(
              this.$t("label_error_domain_conference_need_login")
            );
            this.$emit("input", false);
            return;
          }
          if (userEnterpriseId !== roomEnterpriseId) {
            this.$toast.error(
              this.$t("label_error_domain_conference_incorrect_domain")
            );
            this.$emit("input", false);
            return;
          }
          this.getJoinLinkAndOpenIt();
          break;
        case 2:
          this.showInputPasswordDialog = true;
          break;
        case 3:
          this.getJoinLinkAndOpenIt();
          break;
        default:
          break;
      }
    },
    async getJoinLinkAndOpenIt() {
      const inviteCode = this.inviteCode || this.useRoom.inviteCode;
      const params = {
        p: this.accessCode,
        t: this.currentUserInfo.acutoken,
      };
      const result = await this.getLink(inviteCode, params);
      if (result && result.classUrl) {
        this.showInputPasswordDialog = false;
        this.showVerfiy2fa = false;
        this.waitFor2FA = false;
        this.openLink(result.classUrl);
        // if (this.$utils.isSafari()) {
        //   // safari will block window.open, so detect if is safari, open prompt dialog
        //   this.activeLink = result.classUrl;
        //   this.showPromptSafariDialog = true;
        // } else {
        //   this.showPromptSafariDialog = false;
        // }
      }
    },
    openLink(link) {
      try {
        if (this.$utils.isMobile()) {
          location.href = link;
        } else {
          let openResult = window.open(link);
          if (!openResult) {
            //  open failed
            if (this.$utils.isSafari()) {
              this.showPromptSafariDialog = true;
            }
          }
        }
      } catch (error) {
        process.env.NODE_ENV === "development" && console.error(error);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
</style>