import { repla } from "../utils/stringUtils";

/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-26 12:14:28
 * @LastEditTime: 2021-01-26 12:45:31
 * @FilePath: \acuconference-web\src\mixins\startMeetingApis.js
 */

export default {
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async getRoomInfoByInviteCode(code) {
      this.loading = true;
      const api = repla(process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_ROOM_INFO_BY_INVITE_CODE, {
        code
      })
      process.env.NODE_ENV === 'development' && console.log(api)
      try {
        const result = await this.$axios.get(api)
        this.loading = false
        if (result && result.code === 1) {
          return result.data
        } else {
          throw result
        }
      } catch (error) {
        process.env.NODE_ENV === 'development' && console.error(error)
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    async getLink(code, params) {
      this.loading = true;
      const api = repla(process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_ROOM_LINK_BY_INVITE_CODE, {
        code: encodeURIComponent(code)
      })
      process.env.NODE_ENV === 'development' && console.log(api)
      try {
        const result = await this.$axios.get(api, {
          params
        })
        this.loading = false
        if (result && result.code === 1) {
          return result.data
        } else {
          throw result
        }
      } catch (error) {
        process.env.NODE_ENV === 'development' && console.error(error)
        this.loading && (this.loading = false);
        this.handleError(error)
        return
      }
    },

    handleError(err) {
      let error = this.$errorTranslate(err)
      if (error) {
        this.$toast.error(error);
      }
    }
  },
}